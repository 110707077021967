import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "text-center"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "row my-3 text-center" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "warning" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LookupItemResult = _resolveComponent("LookupItemResult")!
  const _component_DisplayBox = _resolveComponent("DisplayBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DisplayBox, { title: "eBay Item Lookup" }, {
      default: _withCtx(() => [
        (_ctx.lookupItemLoading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Loading..."))
          : _createCommentVNode("", true),
        (_ctx.lookupItemResponse !== null)
          ? (_openBlock(), _createBlock(_component_LookupItemResult, {
              key: 1,
              request: _ctx.lookupItemRequest,
              result: _ctx.lookupItemResponse
            }, null, 8, ["request", "result"]))
          : _createCommentVNode("", true),
        (_ctx.errorMsg !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.errorMsg), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}