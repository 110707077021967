<template>
    <form>
        <div ref="presetMostWatchedToast" class="toast preset-toast text-center position-fixed top-1 start-50 translate-middle-x bg-white" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-body">
                Enter keyword and/or category to search Most Watched.
            </div>
        </div>
        <div ref="presetSoldToast" class="toast preset-toast text-center position-fixed top-1 start-50 translate-middle-x bg-white" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-body">
                Enter keyword and/or category to search Sold.<br>Note: Sold search is only supported for eBay US.
            </div>
        </div>
        <div ref="presetMostBidsToast" class="toast preset-toast text-center position-fixed top-1 start-50 translate-middle-x bg-white" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-body">
                Enter keyword and/or category to search Most Bids.
            </div>
        </div>
        <div ref="presetEbayStandardToast" class="toast preset-toast text-center position-fixed top-1 start-50 translate-middle-x bg-white" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-body">
                Enter keyword and/or category to search eBay Standard.
            </div>
        </div>
        <div ref="presetNoBidsEndingSoonToast" class="toast preset-toast text-center position-fixed top-1 start-50 translate-middle-x bg-white" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-body">
                Enter keyword and/or category to search No Bids Ending Soon.
            </div>
        </div>
        <div class="basic-search-options">
            <div class="row my-3 my-lg-2 align-items-center">
                <div class="col">
                    <div class="form-check form-switch me-0 pe-0">
                        <span class="basic-label switch-label switch-left">Search Live</span>
                        <span v-if="localMostWatchedRequest.site === 'EBAY_US'" id="toggle-tooltip" class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" :title="showToggleTooltip ? 'Slide toggle to search Live vs Completed items.' : ''">
                            <input class="form-check-input status-check mx-2" type="checkbox" v-model="localStatusSwitch">
                        </span>
                        <span v-else id="disable-tooltip" class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="International Completed Search is temporarily down">
                            <input class="form-check-input status-check mx-2" type="checkbox" disabled v-model="localStatusSwitch">
                        </span>
                        <span class="basic-label switch-label">Search Completed</span>
                    </div>
                </div>
            </div>
            <div class="row my-3 my-lg-2 align-items-center">
                <div class="col-12 col-lg-12 col-xl-auto my-4 my-lg-2">
                    <div class="btn-group" role="group">
                        <input type="radio" class="wc-button btn-check" name="listingType" id="listingType1" v-model="localMostWatchedRequest.listingType" :value="listingTypeAll.id" autcomplete="off" checked>
                        <label class="btn wc-button btn-secondary listing-type-button" for="listingType1">{{ listingTypeAll.name }}</label>
                        <input type="radio" class="wc-button btn-check" name="listingType" id="listingType2" v-model="localMostWatchedRequest.listingType" :value="listingTypeAuction.id" autocomplete="off">
                        <label class="btn wc-button btn-secondary listing-type-button" for="listingType2">{{ listingTypeAuction.name }}</label>
                        <input type="radio" class="wc-button btn-check" name="listingType" id="listingType3" v-model="localMostWatchedRequest.listingType" :value="listingTypeFixedPrice.id" autocomplete="off">
                        <label class="btn wc-button btn-secondary listing-type-button" for="listingType3">{{ listingTypeFixedPrice.name }}</label>
                        <input v-if="showListingTypeBestOffer" type="radio" class="wc-button btn-check" name="listingType" id="listingType4" v-model="localMostWatchedRequest.listingType" :value="listingTypeBestOffer.id" autocomplete="off">
                        <label v-if="showListingTypeBestOffer" class="btn wc-button btn-secondary listing-type-button" for="listingType4">{{ listingTypeBestOffer.name }}</label>
                    </div>
                </div>
                <div class="col-12 col-lg-auto pe-lg-0">
                    <label id="sort-by-label" class="col-form-label basic-label">
                        <strong>Sort By:</strong>
                        <div ref="auctionToast" class="toast auction-only-toast text-center" role="alert" aria-live="assertive" aria-atomic="true">
                            <div class="toast-body">
                                The listing type has been updated to Auctions Only.
                            </div>
                        </div>
                    </label>
                </div>
                <div class="col-12 col-lg-auto">
                    <select class="form-select" v-model="localSortBy">
                        <option v-if="showSortByWatchCount" :value="sortByAllWatchersDesc.id">{{ sortByAllWatchersDesc.name }}</option>
                        <option v-if="showSortByBids" :value="sortByAllBidsAsc.id">{{ sortByAllBidsAsc.name }}</option>
                        <option v-if="showSortByBids" :value="sortByAllBidsDesc.id">{{ sortByAllBidsDesc.name }}</option>
                        <option :value="sortByAllBestMatch.id">{{ sortByAllBestMatch.name }}</option>
                        <option :value="sortByAllPriceAsc.id">{{ sortByAllPriceAsc.name }}</option>
                        <option :value="sortByAllPriceDesc.id">{{ sortByAllPriceDesc.name }}</option>
                        <option v-if="showSortByListDate" :value="sortByAllListDateAsc.id">{{ sortByAllListDateAsc.name }}</option>
                        <option v-if="showSortByEndDate" :value="sortByAllEndDateAsc.id">{{ sortByAllEndDateAsc.name }}</option>
                    </select>
                </div>
            </div>
            <div class="row my-2 align-items-center">
                <div class="col-12 col-xl-5">
                    <div class="row align-items-center">
                        <div class="col pe-lg-0">
                            <label class="col-form-label basic-label category-and-keyword-label"><strong>Search Keywords</strong> (or) <strong>eBay Item#:</strong></label>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col">
                            <input class="dark-yellow-input form-control" v-model="localMostWatchedRequest.keywords" type="text" title="For example: enter 'wedding ring'">
                        </div>
                    </div>
                </div>
                <div class="col-12 pt-3 pb-1 col-xl-2 py-xl-0">
                    <div class="row align-items-center d-none d-xl-block">
                       <div class="col and-or-spacer"></div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col text-center txt-xl-left">
                            <span class="category-and-keyword-label and-or-text">and/or</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-5">
                    <div class="row align-items-center">
                        <div class="col pe-lg-0">
                            <label class="col-form-label basic-label category-and-keyword-label"><strong>Category:</strong></label>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col">
                            <select class="form-select light-yellow-input" v-model="localMostWatchedRequest.categoryID" title="Optionally limit your search to a category">
                                <template v-if="subcategoryTree">
                                    <option selected value="">◊ (no category selected) [#0]</option>
                                    <option v-if="subcategoryTree.parent" :value="subcategoryTree.parent.id">↑ {{ subcategoryTree.parent.name }} [#{{ subcategoryTree.parent.id }}]</option>
                                    <option v-if="subcategoryTree.category" :value="subcategoryTree.category.id">• {{ subcategoryTree.category.name }} [#{{ subcategoryTree.category.id }}]</option>
                                    <option v-for="(child, idx) in subcategoryTree.children" :key="idx" :value="child.id">↓ {{ child.name }} [#{{ child.id }}]</option>
                                </template>
                                <template v-else>
                                    <option selected value="">• (no category selected) [#0]</option>
                                    <option v-for="(category, idx) in categories" :key="idx" :value="category.id" :disabled="localMostWatchedRequest.status === statusCompleted.id && category.id === '6000'">↓ {{ category.name }} [#{{ category.id }}] <span v-if="localMostWatchedRequest.status === statusCompleted.id && category.id === '6000'">This is currently unavailable for Completed search.</span></option>
                                </template>
                            </select>
                        </div>
                        <div class="col-12 col-lg-auto">
                            <input class="form-control category-id-input" v-model="localMostWatchedRequest.categoryID" type="text">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-12 text-center">
                    <button v-if="localMostWatchedRequest.keywords !== '' || localMostWatchedRequest.categoryID !== ''" type="submit" class="btn wc-button form-button" :disabled="loading || (localMostWatchedRequest.keywords === '' && localMostWatchedRequest.categoryID === '')">Show Me!</button>
                    <span v-else id="submit-tooltip" class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="Please select a category ID and/or provide keywords to search">
                        <button type="submit" class="btn wc-button form-button" disabled>Show Me!</button>
                    </span>
                    <button class="reset btn wc-button form-button" @click.prevent="reset">Reset</button>
                </div>
            </div>
        </div>
        <div class="advanced-search-options">
            <div class="row my-1 align-items-center">
                <div v-if="showFreeShippingOnly" class="col-6 col-lg-4">
                    <div class="row align-items-center">
                        <div class="col-auto pe-lg-0">
                            <label class="col-form-label advanced-label">Free Shipping Only:</label>
                        </div>
                        <div class="col">
                            <input class="form-check-input" type="checkbox" v-model="localMostWatchedRequest.freeShippingOnly">
                        </div>
                    </div>
                </div>
                <div v-if="showDescriptionSearch" class="col-6 col-lg-4">
                    <div class="row align-items-center">
                        <div class="col-auto pe-lg-0">
                            <label class="col-form-label advanced-label">Descr. Search:</label>
                        </div>
                        <div class="col">
                            <input class="form-check-input" type="checkbox" v-model="localMostWatchedRequest.descriptionSearch">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-1">
                <div v-if="showSeller" class="col-12 col-lg-4">
                    <div class="row align-items-center">
                        <div class="col-12 col-lg-auto pe-lg-0">
                            <label class="col-form-label advanced-label">Seller:</label>
                        </div>
                        <div class="col">
                            <input class="form-control" v-model="localMostWatchedRequest.seller" type="text">
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="row align-items-center">
                        <div class="col-12 col-lg-3 pe-lg-0">
                            <label class="col-form-label advanced-label">Min/Max Price [{{ country.currency }}]:</label>        
                        </div>
                        <div class="col-lg-4 pe-0">
                            <input class="form-control" v-model="localMostWatchedRequest.minPrice" type="number">
                        </div>
                        <div class="col-lg-1 pe-0">
                            <label class="col-form-label advanced-label">to</label>
                        </div>
                        <div class="col-lg-4">
                            <input class="form-control" v-model="localMostWatchedRequest.maxPrice" type="number">
                        </div>
                    </div>
                </div>
                <div v-if="showMinAndMaxBids" class="col-12 col-lg-4">
                    <div class="row align-items-center">
                        <div class="col-12 col-lg-auto pe-lg-0">
                            <label class="col-form-label advanced-label">Min/Max Bids:</label>        
                        </div>
                        <div class="col pe-0">
                            <input class="form-control" v-model="localMostWatchedRequest.minBids" type="number">
                        </div>
                        <div class="col-auto pe-0">
                            <label class="col-form-label advanced-label">to</label>
                        </div>
                        <div class="col">
                            <input class="form-control" v-model="localMostWatchedRequest.maxBids" type="number">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
import { 
    PropType,
    defineComponent
} from "vue";

import {Toast, Tooltip} from 'bootstrap';

import {deepCopy} from '@/utils/copy';
import {
    getCountry
} from '@/utils/country';
import {
    Category,
    ebaySiteUS,
    ListingTypeAll,
    ListingTypeAuction,
    ListingTypeFixedPrice,
    ListingTypeBestOffer,
    ListingTypeOptions,
    MostWatchedRequest,
    SortByAllWatchersDesc,
    SortByAllBidsAsc,
    SortByAllBidsDesc,
    SortByAllBestMatch,
    SortByAllPriceAsc,
    SortByAllPriceDesc,
    SortByAllListDateAsc,
    SortByAllEndDateAsc,
    SortByBestMatch,
    SortByListDate,
    SortByEndDate,
    SortByWatchers,
    SortByBids,
    SortByPrice,
    SortByOptions,
    SortOrderAscending,
    SortOrderDescending,
    SortOrderOptions,
    sortByConversion,
    StatusLive,
    StatusCompleted,
    StatusOptions,
    SubcategoryTree
} from '@/types';

export default defineComponent({
    name: 'FormMostWatched',
    props: {
        mostWatchedRequest: {
            type: Object as PropType<MostWatchedRequest>,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        categories: {
            type: Object as PropType<Array<Category>>,
        },
        subcategoryTree: {
            type: Object as PropType<SubcategoryTree>
        }
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "#disable-tooltip",
        });

        new Tooltip(document.body, {
            selector: "#submit-tooltip",
        });

        if (localStorage.getItem('seen-tooltip-8-14-2024') === null) {
            var toggleTooltipElem = document.getElementById('toggle-tooltip');
            var toggleTooltip = new Tooltip(toggleTooltipElem as Element);
            toggleTooltip.show();

            setTimeout(function() {
                toggleTooltip.hide();
            }, 5000);

            localStorage.setItem('seen-tooltip-8-14-2024', 'true');
        } else {
            this.showToggleTooltip = false;
        }

        this.auctionSortToast = new Toast(this.$refs.auctionToast as Element, {
            autohide: true,
            delay: 3000
        });

        this.presetMostWatchedToast = new Toast(this.$refs.presetMostWatchedToast as Element, {
            autohide: true,
            delay: 3000
        });

        this.presetSoldToast = new Toast(this.$refs.presetSoldToast as Element, {
            autohide: true,
            delay: 3000
        });

        this.presetMostBidsToast = new Toast(this.$refs.presetMostBidsToast as Element, {
            autohide: true,
            delay: 3000
        });

        this.presetEbayStandardToast = new Toast(this.$refs.presetEbayStandardToast as Element, {
            autohide: true,
            delay: 3000
        });

        this.presetNoBidsEndingSoonToast = new Toast(this.$refs.presetNoBidsEndingSoonToast as Element, {
            autohide: true,
            delay: 3000
        });

        if (this.$route.query.preset === 'mw') {
            this.presetMostWatchedToast.show();
        } else if (this.$route.query.preset === 's') {
            this.presetSoldToast.show();
        } else if (this.$route.query.preset === 'mb') {
            this.presetMostBidsToast.show();
        } else if (this.$route.query.preset === 'es') {
            this.presetEbayStandardToast.show();
        } else if (this.$route.query.preset === 'nbes') {
            this.presetNoBidsEndingSoonToast.show();
        }
    },
    data() {
        return {
            country: getCountry(),

            listingTypeAll: ListingTypeAll,
            listingTypeAuction: ListingTypeAuction,
            listingTypeFixedPrice: ListingTypeFixedPrice,
            listingTypeBestOffer: ListingTypeBestOffer,

            statusOptions: StatusOptions,
            statusCompleted: StatusCompleted,

            sortByAllWatchersDesc: SortByAllWatchersDesc,
            sortByAllBidsAsc: SortByAllBidsAsc,
            sortByAllBidsDesc: SortByAllBidsDesc,
            sortByAllBestMatch: SortByAllBestMatch,
            sortByAllPriceAsc: SortByAllPriceAsc,
            sortByAllPriceDesc: SortByAllPriceDesc,
            sortByAllListDateAsc: SortByAllListDateAsc,
            sortByAllEndDateAsc: SortByAllEndDateAsc,

            sortByWatchers: SortByWatchers,
            sortByBids: SortByBids,
            sortByBestMatch: SortByBestMatch,
            sortByPrice: SortByPrice,
            sortByListDate: SortByListDate,
            sortByEndDate: SortByEndDate,
            sortByOptions: SortByOptions,

            sortOrderAscending: SortOrderAscending,
            sortOrderDescending: SortOrderDescending,
            
            sortOrderOptions: SortOrderOptions,
            
            showSortByListDate: this.mostWatchedRequest.status === StatusLive.id,
            showSortByEndDate: this.mostWatchedRequest.status === StatusLive.id,
            showSortByBids: this.mostWatchedRequest.status === StatusLive.id,
            showSortByWatchCount: this.mostWatchedRequest.status === StatusLive.id,
            showFreeShippingOnly: this.mostWatchedRequest.status === StatusLive.id,
            showDescriptionSearch: this.mostWatchedRequest.status === StatusLive.id,
            showSeller: this.mostWatchedRequest.status === StatusLive.id,
            
            showMinAndMaxBids: this.mostWatchedRequest.status === StatusLive.id && this.mostWatchedRequest.listingType === ListingTypeAuction.id,

            showListingTypeBestOffer: this.mostWatchedRequest.status === StatusLive.id,

            localStatusSwitch: this.mostWatchedRequest.status === StatusCompleted.id,

            localSortBy: sortByConversion(this.mostWatchedRequest.sortBy, this.mostWatchedRequest.sortOrder),

            localMostWatchedRequest: deepCopy(this.mostWatchedRequest) as MostWatchedRequest,

            auctionSortToast: null as Toast | null,
            presetMostWatchedToast: null as Toast | null,
            presetSoldToast: null as Toast | null,
            presetMostBidsToast: null as Toast | null,
            presetEbayStandardToast: null as Toast | null,
            presetNoBidsEndingSoonToast: null as Toast | null,

            showToggleTooltip: true
        };
    },
    methods: {
        reset() {
            this.localMostWatchedRequest = {
                keywords: '',
                categoryID: '',
                listingType: ListingTypeAll.id,
                freeShippingOnly: false,
                descriptionSearch: false,
                seller: '',
                minPrice: null,
                maxPrice: null,
                minBids: null,
                maxBids: null,
                sortBy: this.localMostWatchedRequest.sortBy,
                sortOrder: this.localMostWatchedRequest.sortOrder,
                status: this.localMostWatchedRequest.status,
                site: this.localMostWatchedRequest.site,
                offset: 0
            };

            this.$emit('reset', true);
        }
    },
    watch: {
        localMostWatchedRequest: {
            handler(val) {
                this.$emit('updateMostWatchedRequest', val);
            },
            deep: true
        },
        'localMostWatchedRequest.status': {
            handler(val) {
                if (val === StatusLive.id) {
                    if (this.localMostWatchedRequest.listingType === ListingTypeAuction.id) {
                        this.showMinAndMaxBids = true;
                    }

                    this.showSortByListDate = true;
                    this.showSortByWatchCount = true;
                    this.showSortByEndDate = true;
                    this.showSortByBids = true;

                    this.showFreeShippingOnly = true;
                    this.showDescriptionSearch = true;
                    this.showSeller = true;
                    this.showListingTypeBestOffer = true;
                    this.localStatusSwitch = false;
                } else {
                    if (this.localSortBy === SortByAllListDateAsc.id) {
                        this.localSortBy = SortByAllPriceDesc.id;
                    } else if (this.localSortBy === SortByAllWatchersDesc.id) {
                        this.localSortBy = SortByAllPriceDesc.id;
                    } else if (this.localSortBy === SortByAllEndDateAsc.id) {
                        this.localSortBy = SortByAllPriceDesc.id;
                    } else if (this.localSortBy === SortByAllBidsAsc.id || this.localSortBy === SortByAllBidsDesc.id) {
                        this.localSortBy = SortByAllPriceDesc.id;
                    }

                    if (this.localMostWatchedRequest.listingType === ListingTypeBestOffer.id) {
                        this.localMostWatchedRequest.listingType = ListingTypeAll.id;
                    }

                    this.showSortByListDate = false;
                    this.showSortByWatchCount = false;
                    this.showSortByEndDate = false;
                    this.showSortByBids = false;

                    this.showFreeShippingOnly = false;
                    this.showDescriptionSearch = false;
                    this.showSeller = false;
                    this.localStatusSwitch = true;
                    this.showMinAndMaxBids = false;
                    this.showListingTypeBestOffer = false;

                    this.localMostWatchedRequest.freeShippingOnly = false;
                    this.localMostWatchedRequest.descriptionSearch = false;
                    this.localMostWatchedRequest.seller = '';
                    this.localMostWatchedRequest.minBids = null;
                    this.localMostWatchedRequest.maxBids = null;
                }
            },
            deep: true
        },
        'localMostWatchedRequest.listingType': {
            handler(val) {
                if (this.localMostWatchedRequest.listingType === ListingTypeAuction.id) {
                    if (this.localMostWatchedRequest.status === StatusLive.id) {
                        this.showMinAndMaxBids = true;
                    }
                } else {
                    if (this.localMostWatchedRequest.sortBy === SortByBids.id) {
                        this.localSortBy = SortByAllWatchersDesc.id;
                    }

                    this.localMostWatchedRequest.minBids = null;
                    this.localMostWatchedRequest.maxBids = null;

                    this.showMinAndMaxBids = false;
                }
            },
            deep: true
        },
        localStatusSwitch: {
            handler(val) {
                if (this.localStatusSwitch) {
                    this.localMostWatchedRequest.status = StatusCompleted.id;
                } else {
                    this.localMostWatchedRequest.status = StatusLive.id;
                }
            },
            deep: true
        },
        localSortBy: {
            handler(val, oldVal) {
                if (this.localSortBy === SortByAllWatchersDesc.id) {
                    this.localMostWatchedRequest.sortBy = SortByWatchers.id;
                    this.localMostWatchedRequest.sortOrder = SortOrderDescending.id;
                } else if (this.localSortBy === SortByAllBidsAsc.id) {
                    this.localMostWatchedRequest.sortBy = SortByBids.id;
                    this.localMostWatchedRequest.sortOrder = SortOrderAscending.id;
                    if (this.auctionSortToast && this.localMostWatchedRequest.listingType !== ListingTypeAuction.id) {
                        this.auctionSortToast.show();
                    }
                    this.localMostWatchedRequest.listingType = ListingTypeAuction.id;
                } else if (this.localSortBy === SortByAllBidsDesc.id) {
                    this.localMostWatchedRequest.sortBy = SortByBids.id;
                    this.localMostWatchedRequest.sortOrder = SortOrderDescending.id;
                    if (this.auctionSortToast && this.localMostWatchedRequest.listingType !== ListingTypeAuction.id) {
                        this.auctionSortToast.show();
                    }
                    this.localMostWatchedRequest.listingType = ListingTypeAuction.id;
                } else if (this.localSortBy === SortByAllBestMatch.id) {
                    this.localMostWatchedRequest.sortBy = SortByBestMatch.id;
                    this.localMostWatchedRequest.sortOrder = SortOrderDescending.id;
                } else if (this.localSortBy === SortByAllPriceAsc.id) {
                    this.localMostWatchedRequest.sortBy = SortByPrice.id;
                    this.localMostWatchedRequest.sortOrder = SortOrderAscending.id;
                } else if (this.localSortBy === SortByAllPriceDesc.id) {
                    this.localMostWatchedRequest.sortBy = SortByPrice.id;
                    this.localMostWatchedRequest.sortOrder = SortOrderDescending.id;
                } else if (this.localSortBy === SortByAllListDateAsc.id) {
                    this.localMostWatchedRequest.sortBy = SortByListDate.id;
                    this.localMostWatchedRequest.sortOrder = SortOrderAscending.id;
                } else if (this.localSortBy === SortByAllEndDateAsc.id) {
                    this.localMostWatchedRequest.sortBy = SortByEndDate.id;
                    this.localMostWatchedRequest.sortOrder = SortOrderAscending.id;
                }
            },
            deep: true
        }
    }
});
</script>

<style scoped>
    .basic-label {
        font-family: Tahoma,Geneva,Verdana,Arial,Helvetica;
        font-size: 10pt;
        color: #444444;
        font-weight: normal;
    }
    .wc-button.btn {
        padding: 0px 6px 1px 6px;
        font-family: Tahoma,Geneva,Verdana,Arial ;
        font-size: 10pt ;
        font-weight: bold ;
        color: #111111 ;
        background-color: #f0f0f0 ;
        border: 1px outset #767676 ;
        border-radius: 0px;
    }
    .advanced-search-options {
        border: 1px #767676 dotted;
        padding: 10px 5px 10px 10px;
        margin: 18px 8px 14px 10px;
    }
    .advanced-label {
        font-family: Verdana,Arial,Geneva,Tahoma;
        font-size: 8pt;
        font-weight: bold;
        color: #808080;
        background: transparent;
    }
    .switch-label {
        font-family: Tahoma,Geneva,Verdana,Arial,Helvetica;
        font-size: 14pt;
        color: #444444;
        font-weight: bold;
    }
    .switch-left {
        float: left;
    }
    .form-switch {
        padding: 0 !important;
    }
    .reset {
        margin-left: 15px;
    }
    .listing-type-button {
        border-radius: 10px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
    .wc-button.btn-check:checked + .wc-button.btn, :not(.btn-check) + .wc-button.btn:active, .wc-button.btn:first-child:active, .wc-button.btn.active, .wc-button.btn.show {
        background: url('@/assets/fade-blue.jpg') repeat-x scroll top left !important;
        background-position-y: center !important;
        color: #444444 !important;
    }
    .status-check {
        width: 45px !important;
        height: 17px !important;
        margin-top: 5px !important;
    }
    
    .dark-yellow-input {
        background: #ffffb8;
        border: 1px solid #2f2f2f;
    }
    .dark-yellow-input:focus {
        background: #ffffb8 !important;
        border: 1px solid #2f2f2f;
    }

    .light-yellow-input {
        background: #ffffb8;
        border: 1px solid #2f2f2f;
    }

    #sort-by-label {
        position: relative;
    }

    .auction-only-toast {
        font-size: 12px;
        text-align: center;
        position: absolute;
        z-index: 11;
        min-width: 300px;
        max-width: 300px;
        left: 25px;
        top: -50px;
    }

    .preset-button.btn {
        background-color: #ffffff;
        border: 1px solid #2f2f2f;
        color: #2f2f2f;
        font-size: 12px;
    }
    .preset-button.btn:hover {
        background-color: #e6e6e6;
    }
    .preset-button.btn-check:checked + .preset-button.btn, :not(.preset-button.btn-check) + .preset-button.btn:active, .preset-button.btn:first-child:active, .preset-button.btn.active, .preset-button.btn.show {
        background: url('@/assets/fade-blue.jpg') repeat-x scroll top left !important;
        color: #444444 !important;
    }

    .toast {
        z-index: 99;
    }

    .preset-toast {
        font-size: 12px;
        background-color: #ffc36e !important;
        border: 1px solid black;
    }

    .category-and-keyword-label {
        font-size: 11px;
        color: #990000;
    }

    .category-id-input {
        width: 100px;
    }

    .and-or-text {
        font-size: 14px;
    }

    .and-or-spacer {
        height: 30px;
    }

    @media only screen and (min-width:801px) and (max-width: 1199px) {
        .auction-only-toast {
            font-size: 12px;
            left: 360px;
            top: -5px;
        }
    }

    @media only screen and (max-width: 991px) {
        .auction-only-toast {
            font-size: 8px;
            min-width: 210px;
            max-width: 150px;
            left: 60px;
            top: -15px;
        }
    }

    @media only screen and (max-width: 800px) {
        .listing-type-button {
            height: 40px !important;
        }
    }
    @media only screen and (max-width: 500px) {
        .listing-type-button {
            height: 50px !important;
            font-size: 8pt !important;
        }

        .preset-button {
            height: 50px !important;
        }
    }

    @media only screen and (max-width: 800px) {
        .form-button {
            height: 40px !important;
        }
    }

    @media only screen and (max-width: 450px) {
        .switch-label {
            font-size:10pt;
        }
    }
</style>