<template>
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <span class="red">Item Number:</span> <a class="purple" :href="itemLink" target="_blank">{{ result.itemID }}</a>
            </div>
            <div class="col text-center">
                <span class="red">Site:</span> <a class="purple" :href="siteLink" target="_blank">{{ siteName }}</a>
            </div>
            <div class="col text-center">
                <img class="image" :src="require('@/assets/right-now-on-ebay.gif')">
            </div>
        </div>
        <div class="row item">
            <div class="col">
                <div class="row mb-4">
                    <div class="col-12 col-lg-4 text-center">
                        <a :href="itemLink" target="_blank">
                            <img class="image" :src="result.image">
                        </a>
                    </div>
                    <div class="col-12 col-lg-8 text-center">
                        <div class="row">
                            <div class="col text-start">
                                <a class="blue large-text" :href="itemLink" target="_blank">{{ result.title }}</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-start">
                                <span class="gray">{{ result.listingType }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-start">
                                <span class="gray">Time Left:</span> <span class="black" v-if="timeLeft">{{ timeLeft }}</span><span v-else>N/A</span>
                            </div>
                            <div v-if="result.completed !== undefined && result.completed !== null" class="col text-end">
                                <span class="gray" v-if="result.completed">Completed</span>
                                <span v-else class="green">Active</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mx-0 mx-lg-5">
                    <div class="row">
                        <div class="col">
                            <span class="gray">Seller:</span> <a class="blue" :href="sellerLink" target="_blank">{{ result.seller }}</a>
                        </div>
                        <div class="col text-end">
                            <span class="watch-count">
                                Watch Count: <span class="" v-if="watchCount !== undefined && result.watchCount !== null">{{ watchCount.toLocaleString() }}</span><span class="gray" v-else>(unavailable)</span>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="gray">Start Time:</span> <span class="black" v-if="result.startTime">{{ startTime }}</span><span class="gray" v-else>(unavailable)</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="gray">End Time:</span> <span class="black" v-if="result.endTime">{{ endTime }} <span v-if="daysEndedAgo" class="gray">({{ daysEndedAgo }} days ago)</span></span><span class="gray" v-else>(unavailable)</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="gray">Current Bid/Price: ({{ result.currency }})</span> <span class="black" v-if="price !== undefined && price !== null">{{ price }}</span><span class="gray" v-else>(unavailable)</span>
                        </div>
                        <div v-if="result.bestOfferEnabled" class="col text-end">
                            <span class="red">Best Offer Enabled</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="gray">Bid Count:</span> <span class="black" v-if="result.bids !== null && result.bids !== undefined">{{ result.bids }}</span><span class="gray" v-else>(unavailable)</span>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            [<a class="purple" :href="historyLink" target="_blank">purchase history</a>]
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="gray">Listed on Site:</span> <span class="green">{{ siteName }}</span>
                        </div>
                        <div class="col text-end">
                            <span class="gray">In Country:</span> <span class="black" v-if="result.country">{{ result.country }}</span><span class="gray" v-else>(unavailable)</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="gray">Location:</span> <span class="black" v-if="result.location">{{ result.location }}</span><span class="gray" v-else>(unavailable)</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="gray">Primary Category:</span> <span class="black" v-if="result.categoryPath">{{ result.categoryPath }} [<a class="purple" :href="mostWatchedCategoryLink" target="_blank">most watched</a>] [<a class="purple" :href="mostBidsCategoryLink" target="_blank">most bids</a>]</span><span class="gray" v-else>(unavailable)</span>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col text-center">
                            <span class="gray">Quantity Total:</span> <span class="black" v-if="quantityTotal !== undefined && quantityTotal !== null">{{ quantityTotal.toLocaleString() }}</span><span class="gray" v-else>(unavailable)</span>
                        </div> -->
                        <div class="col text-start">
                            <span class="gray">Sold:</span> <span class="black" v-if="quantitySold !== undefined && quantitySold !== null">{{ quantitySold.toLocaleString() }}</span><span class="gray" v-else>(unavailable)</span>
                        </div>
                        <!-- <div class="col text-center">
                            <span class="gray">Available:</span> <span class="black" v-if="quantityAvailable !== undefined && quantityAvailable !== null">{{ quantityAvailable.toLocaleString() }}</span><span class="gray" v-else>(unavailable)</span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { 
    PropType,
    defineComponent
} from "vue";

import {
    encodeLookupItemRequest,
    ebaySiteMap,
    LookupItemRequest,
    LookupItemResponse
} from '@/types';
import {
    getCountry
} from '@/utils/country';
import {
    createRedirectURL,
    createFullRedirectURL
} from '@/utils/redirector';

export default defineComponent({
    name: 'LookupItemResult',
    props: {
        request: {
            type: Object as PropType<LookupItemRequest>,
            required: true
        },
        result: {
            type: Object as PropType<LookupItemResponse>,
            required: true
        }
    },
    data() {
        return {
            'country': getCountry(),
            'createRedirectURL': createRedirectURL
        }
    },
    computed: {
        itemLink: {
            get(): string {
                return createRedirectURL(this.siteName, this.result.itemID, '', '', encodeLookupItemRequest(this.request), this.result.requestId)
            },
            set() {

            }
        },
        siteName: {
            get(): string {
                return ebaySiteMap.get(this.result.site)!.site;
            },
            set() {

            }
        },
        siteLink: {
            get(): string {
                return createRedirectURL(this.siteName, '', '', '', encodeLookupItemRequest(this.request), this.result.requestId);
            },
            set() {

            }
        },
        timeLeft: {
            get(): string {
                if (!this.result.endTime) {
                    return '';
                }

                // Get current date and time
                const now = new Date();

                // Get the difference in milliseconds
                const differenceInMs = new Date(this.result.endTime).getTime() - now.getTime();

                if (differenceInMs < 0) {
                    return '';
                }

                // Calculate days, hours, minutes, and seconds
                const days = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
                const hours = Math.floor((differenceInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((differenceInMs % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((differenceInMs % (1000 * 60)) / 1000);

                // Format the output string
                return `${days}d ${hours}h ${minutes}m ${seconds}s`;
            },
            set() {

            }
        },
        sellerLink: {
            get(): string {
                return createRedirectURL(this.siteName, '', this.result.seller, '', encodeLookupItemRequest(this.request), this.result.requestId)
            },
            set() {

            }
        },
        feedbackLink: {
            get(): string {
                const link = 'https://' + this.siteName + '/usr/' + this.result.seller + '?_tab=feedback';

                return createFullRedirectURL(link, encodeLookupItemRequest(this.request), this.result.requestId);
            },
            set() {

            }
        },
        startTime: {
            get(): string {
                if (!this.result.startTime) {
                    return '';
                }

                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                const startTime = new Date(this.result.startTime);

                const dayName = days[startTime.getUTCDay()];
                const day = String(startTime.getUTCDate()).padStart(2, '0');
                const monthName = months[startTime.getUTCMonth()];
                const year = startTime.getUTCFullYear().toString().slice(-2); // Get last two digits of year
                const hours = String(startTime.getUTCHours()).padStart(2, '0');
                const minutes = String(startTime.getUTCMinutes()).padStart(2, '0');
                const seconds = String(startTime.getUTCSeconds()).padStart(2, '0');

                // Format the date string
                return `${dayName}, ${day}-${monthName}-${year} ${hours}:${minutes}:${seconds} UTC`;
            },
            set() {

            }
        },
        endTime: {
            get(): string {
                if (!this.result.endTime) {
                    return '';
                }

                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                const endTime = new Date(this.result.endTime);

                const dayName = days[endTime.getUTCDay()];
                const day = String(endTime.getUTCDate()).padStart(2, '0');
                const monthName = months[endTime.getUTCMonth()];
                const year = endTime.getUTCFullYear().toString().slice(-2); // Get last two digits of year
                const hours = String(endTime.getUTCHours()).padStart(2, '0');
                const minutes = String(endTime.getUTCMinutes()).padStart(2, '0');
                const seconds = String(endTime.getUTCSeconds()).padStart(2, '0');

                // Format the date string
                return `${dayName}, ${day}-${monthName}-${year} ${hours}:${minutes}:${seconds} UTC`;
            },
            set() {

            }
        },
        daysEndedAgo: {
            get(): string {
                if (!this.result.endTime) {
                    return '';
                }

                const endTime = new Date(this.result.endTime);

                // Get current date and time
                const now = new Date();

                // Get difference in days (up to 2 decimals)
                const differenceInMs = now.getTime() - endTime.getTime();

                if (differenceInMs < 0) {
                    return '';
                }

                const msInDay = 1000 * 60 * 60 * 24;
                const differenceInDays = differenceInMs / msInDay;
                const roundedDays = differenceInDays.toFixed(2);

                return roundedDays; 
            },
            set() {

            }
        },
        mostWatchedCategoryLink: {
            get(): string {
                return `/mw2?categoryID=${this.result.categoryID}&sortBy=watchcount&sortOrder=desc&site=${this.result.site}`;
            },
            set() {

            }
        },
        mostBidsCategoryLink: {
            get(): string {
                return `/mw2?categoryID=${this.result.categoryID}&listingType=auction&sortBy=bids&sortOrder=desc&site=${this.result.site}`;
            },
            set() {

            }
        },
        historyLink: {
            get(): string {
                const link = 'https://' + this.siteName + '/bin/purchaseHistory/' + this.result.itemID;

                return createFullRedirectURL(link, encodeLookupItemRequest(this.request), this.result.requestId);
            },
            set() {

            }
        },
        watchCount: {
            get(): number | null {
                if (this.result.watchCount === undefined || this.result.watchCount === null) {
                    return null;
                }

                return this.result.watchCount[0];
            },
            set() {

            }
        },
        price: {
            get(): string | null {
                if (this.result.price === undefined || this.result.price === null) {
                    return null;
                }

                if (this.result.price[0] === this.result.price[this.result.price.length-1]) {
                    return this.result.price[0].toLocaleString('en-US', {style: 'currency', currency: this.result.currency})
                }

                return this.result.price[0].toLocaleString('en-US', {style: 'currency', currency: this.result.currency}) + ' to ' + this.result.price[this.result.price.length-1].toLocaleString('en-US', {style: 'currency', currency: this.result.currency});
            },
            set() {

            }
        },
        quantitySold: {
            get(): number | null {
                if (this.result.quantitySold === undefined || this.result.quantitySold === null) {
                    return null;
                }

                if (this.result.quantitySold.length === 0) {
                    return null;
                }

                return this.result.quantitySold.reduce((a, b) => a + b, 0);
            },
            set() {

            }
        },
        quantityAvailable: {
            get(): number | null {
                if (this.result.quantityAvailable === undefined || this.result.quantityAvailable === null) {
                    return null;
                }

                if (this.result.quantityAvailable.length === 0) {
                    return null;
                }

                return this.result.quantityAvailable.reduce((a, b) => a + b, 0);
            },
            set() {

            }
        },
        quantityTotal: {
            get(): number | null {
                if (this.quantitySold === undefined || this.quantitySold === null  || this.quantityAvailable === undefined || this.quantityAvailable === null) {
                    return null;
                }

                return this.quantitySold + this.quantityAvailable;
            },
            set() {

            }
        }
    }
})
</script>

<style scoped>
    .item {
        background: url('@/assets/fade-blue-2.png') repeat-x scroll top left;
        padding: 15px;
        background-size: contain;
    }

    .image {
        max-height: 140px;
        max-width: 140px;
    }

    .large-text {
        font-size: 14pt;
    }

    .watch-count {
        color: #262626;
        font-family: Arial,Helvetica,Verdana;
        background-color: #ffff80;
        border-radius: 5px 0px 0px 5px;
        font-weight: bold;
    }

    .light-gray {
        color: #9a9a9a;
        font-weight: normal;
        font-family: Arial,Helvetica,Verdana;
        font-weight: bold;
    }

    .gray {
        color: #636262;
        font-weight: normal;
        font-family: Arial,Helvetica,Verdana;
        font-weight: bold;
    }

    .black {
        color: #262626;
        font-family: Arial,Helvetica,Verdana;
        font-weight: bold;
    }

    .purple {
        color: #7812cc;
        text-decoration: none;
        font-family: Arial,Helvetica,Verdana;
        font-weight: bold;
    }
    .purple:hover {
        color: 3002699;
        text-decoration: underline;
    }

    .blue {
        color: rgb(3, 3, 212);
        font-family: Arial,Helvetica,Verdana;
        font-weight: bold;
    }

    .red {
        color: #bf2828;
        font-family: Arial,Helvetica,Verdana;
        font-weight: bold;
    }

    .green {
        color: green;
        font-weight: bold;
    }
</style>