<template>
    <div class="top-categories">
        <div class="py-2 py-lg-0" v-for="(category, idx) in categories" :key="idx">
            <a class="category-link" :href="'/mw2?categoryID=' + category.id">{{ category.name }}</a>
        </div>
    </div>
</template>

<script lang="ts">
import {
    PropType,
    defineComponent
} from "vue";

import {
    Category
} from '@/types';

export default defineComponent({
    name: 'TopCategories',
    props: {
        categories: {
            type: Object as PropType<Array<Category>>,
        }
    }
});
</script>

<style scoped>
    .top-categories {
        background-color: #f6f6ff;
    }

    .category-link {
        color: #7812CC;
        text-decoration: none;
        font-family: Tahoma,Verdana,Helvetica;
        font-size: 8pt;
        font-weight: normal;
    }
    .category-link:hover {
        color: #002699;
        text-decoration: underline;
    }
</style>