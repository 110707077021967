import {
    EbaySite,
    ebaySiteMap,
    ebaySiteUS
} from '@/types';

const countryCodeLocalStorageKey = 'countryCode';

// Read country code from local storage. If nothing is present in local storage, assume US.
export function getCountry(): EbaySite {
    const urlParams = new URLSearchParams(window.location.search);
    const urlSite = urlParams.get('site') || '';


    if (ebaySiteMap.get(urlSite)) {
        setCountryCode(urlSite);
    }

    return ebaySiteMap.get(
        urlSite || localStorage.getItem(countryCodeLocalStorageKey) || ebaySiteUS.id
    ) || ebaySiteUS;
}

// Set the country code in local storage.
export function setCountryCode(countryID: string) {
    return localStorage.setItem(countryCodeLocalStorageKey, countryID);
}