export function createRedirectURL(site: string, itemID: string, seller: string, status: string, search: string, requestID: string) {
    var host = '';
    if (process.env.NODE_ENV === 'development') {
        host = 'http://localhost:8083'
    } else {
        host = 'https://redirector-nvfdubtz5a-uc.a.run.app'
    }

    const encodedSearch = btoa(search);

    return `${host}/go?site=${site}&item=${itemID}&seller=${seller}&status=${status}&search=${encodedSearch}&requestID=${requestID}`;
}

export function createFullRedirectURL(link: string, search: string, requestID: string) {
    var host = '';
    if (process.env.NODE_ENV === 'development') {
        host = 'http://localhost:8083'
    } else {
        host = 'https://redirector-nvfdubtz5a-uc.a.run.app'
    }

    const encodedLink = encodeURI(link)

    const encodedSearch = btoa(search);

    return `${host}/go?fullLink=${encodedLink}&search=${encodedSearch}&requestID=${requestID}`;
}