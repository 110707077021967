export interface EbaySite {
    id: string,
    code: string,
    name: string,
    site: string,
    currency: string,
    locale: string,
}
export var ebaySiteUS = {id: 'EBAY_US', code: 'US', name: 'USA', site: 'ebay.com', currency: 'USD', locale: ''} as EbaySite;
export var ebaySiteUK = {id: 'EBAY_GB', code: 'UK', name: 'UK', site: 'ebay.co.uk', currency: 'GBP', locale: ''} as EbaySite;
export var ebaySiteAU = {id: 'EBAY_AU', code: 'AU', name: 'Australia', site: 'ebay.com.au', currency: 'AUD', locale: ''} as EbaySite;
export var ebaySiteCA = {id: 'EBAY_CA', code: 'CA', name: 'Canada (English)', site: 'ebay.ca', currency: 'CAD', locale: ''} as EbaySite;
export var ebaySiteIE = {id: 'EBAY_IE', code: 'IE', name: 'Ireland', site: 'ebay.ie', currency: 'EUR', locale: ''} as EbaySite;
export var ebaySiteDE = {id: 'EBAY_DE', code: 'DE', name: 'Deutschland', site: 'ebay.de', currency: 'EUR', locale: ''} as EbaySite;
export var ebaySiteFR = {id: 'EBAY_FR', code: 'FR', name: 'France', site: 'ebay.fr', currency: 'EUR', locale: ''} as EbaySite;
export var ebaySiteES = {id: 'EBAY_ES', code: 'ES', name: 'España', site: 'ebay.es', currency: 'EUR', locale: ''} as EbaySite;
export var ebaySiteIT = {id: 'EBAY_IT', code: 'IT', name: 'Italia', site: 'ebay.it', currency: 'EUR', locale: ''} as EbaySite;
export var ebaySiteNL = {id: 'EBAY_NL', code: 'NL', name: 'Nederland', site: 'ebay.nl', currency: 'EUR', locale: ''} as EbaySite;
export var ebaySiteBENL = {id: 'EBAY_BEN', code: 'BENL', name: 'België', site: 'benl.ebay.be', currency: 'EUR', locale: 'nl-BE'} as EbaySite;
export var ebaySiteBEFR = {id: 'EBAY_BEF', code: 'BEFR', name: 'Belgique', site: 'befr.ebay.be', currency: 'EUR', locale: 'fr-BE'} as EbaySite;
export var ebaySiteAT = {id: 'EBAY_AT', code: 'AT', name: 'Österreich', site: 'ebay.at', currency: 'EUR', locale: ''} as EbaySite;
export var ebaySiteCH = {id: 'EBAY_CH', code: 'CH', name: 'Schweiz', site: 'ebay.ch', currency: 'CHF', locale: ''} as EbaySite;
export var ebaySiteMY = {id: 'EBAY_MY', code: 'MY', name: 'Malaysia', site: 'ebay.com.my', currency: 'MYR', locale: ''} as EbaySite;
export var ebaySiteIN = {id: 'EBAY_IN', code: 'IN', name: 'India', site: 'ebay.com', currency: 'INR', locale: ''} as EbaySite;
export var ebaySiteSG = {id: 'EBAY_SG', code: 'SG', name: 'Singapore', site: 'ebay.com.sg', currency: 'SGD', locale: ''} as EbaySite;
export var ebaySitePH = {id: 'EBAY_PH', code: 'PH', name: 'Philippines', site: 'ebay.ph', currency: 'PHP', locale: ''} as EbaySite;
export var ebaySites: EbaySite[] = [
    ebaySiteUS,
    ebaySiteUK,
    ebaySiteAU,
    ebaySiteCA,
    ebaySiteIE,
    ebaySiteDE,
    ebaySiteFR,
    ebaySiteES,
    ebaySiteIT,
    ebaySiteNL,
    ebaySiteBENL,
    ebaySiteBEFR,
    ebaySiteAT,
    ebaySiteCH,
    ebaySiteMY,
    ebaySiteIN,
    ebaySiteSG,
    ebaySitePH
];
export var ebaySiteMap = new Map<string, EbaySite>();
for (var i = 0; i < ebaySites.length; i++) {
    ebaySiteMap.set(ebaySites[i].id, ebaySites[i]);
}

export interface SortByAll {
    id: string,
    name: string
}
export var SortByAllWatchersDesc = {id: 'watchcount-desc', name: 'Watch Count (Highest First)'} as SortByAll;
export var SortByAllBidsAsc = {id: 'bids-asc', name: 'Bids (Lowest First)'} as SortByAll;
export var SortByAllBidsDesc = {id: 'bids-desc', name: 'Bids (Highest First)'} as SortByAll;
export var SortByAllBestMatch = {id: 'bestmatch', name: 'Best Match'} as SortByAll;
export var SortByAllPriceAsc = {id: 'price-asc', name: 'Price+Shipping (Lowest First)'} as SortByAll;
export var SortByAllPriceDesc = {id: 'price-desc', name: 'Price+Shipping (Highest First)'} as SortByAll;
export var SortByAllListDateAsc = {id: 'listdate-asc', name: 'Newly Listed'} as SortByAll;
export var SortByAllEndDateAsc = {id: 'enddate-asc', name: 'Ending Soonest'} as SortByAll;

export interface SortBy {
    id: string,
    name: string
}
export var SortByWatchers = {id: 'watchcount', name: 'Watch Count'} as SortBy;
export var SortByBids = {id: 'bids', name: 'Bids'} as SortBy;
export var SortByBestMatch = {id: 'bestmatch', name: 'Best Match'} as SortBy;
export var SortByPrice = {id: 'price', name: 'Price (shipping-included)'} as SortBy;
export var SortByListDate = {id: 'listdate', name: 'List Date'} as SortBy;
export var SortByEndDate = {id: 'enddate', name: 'End Date'} as SortBy;
export var SortByOptions = [
    SortByWatchers,
    SortByBids,
    SortByBestMatch,
    SortByPrice,
    SortByListDate,
    SortByEndDate
];
export var sortByMap = new Map<string, SortBy>();
for (var i = 0; i < SortByOptions.length; i++) {
    sortByMap.set(SortByOptions[i].id, SortByOptions[i]);
}

export interface SortOrder {
    id: string,
    name: string
}
export var SortOrderAscending = {id: 'asc', name: 'Ascending'} as SortOrder;
export var SortOrderDescending = {id: 'desc', name: 'Descending'} as SortOrder;
export var SortOrderOptions = [
    SortOrderAscending,
    SortOrderDescending
];

export function sortByConversion(sortBy: string, sortOrder: string): string {
    if (sortBy === SortByWatchers.id) {
        return SortByAllWatchersDesc.id;
    } else if (sortBy === SortByBids.id && sortOrder === SortOrderAscending.id) {
        return SortByAllBidsAsc.id;
    } else if (sortBy === SortByBids.id && sortOrder === SortOrderDescending.id) {
        return SortByAllBidsDesc.id;
    } else if (sortBy === SortByBestMatch.id) {
        return SortByAllBestMatch.id;  
    } else if (sortBy === SortByPrice.id && sortOrder === SortOrderAscending.id) {
        return SortByAllPriceAsc.id;
    } else if (sortBy === SortByPrice.id && sortOrder === SortOrderDescending.id) {
        return SortByAllPriceDesc.id;
    } else if (sortBy === SortByListDate.id) {
        return SortByAllListDateAsc.id;
    } else if (sortBy === SortByEndDate.id) {
        return SortByAllEndDateAsc.id;
    }

    return SortByAllWatchersDesc.id;
}

export interface Status {
    id: string,
    name: string
}
export var StatusLive = {id: 'live', name: 'Search Live'} as Status;
export var StatusCompleted = {id: 'completed', name: 'Search Completed'} as Status;
export var StatusOptions = [
    StatusLive,
    StatusCompleted
];

export interface ListingType {
    id: string,
    name: string
}
export var ListingTypeAll = {id: 'all', name: 'All Item Types'} as ListingType;
export var ListingTypeAuction = {id: 'auction', name: 'Auctions Only'} as ListingType;
export var ListingTypeFixedPrice = {id: 'fixedprice', name: 'Fixed-Price/BIN Only'} as ListingType;
export var ListingTypeBestOffer = {id: 'bestoffer', name: 'Best Offer Only'} as ListingType;
export var ListingTypeOptions = [
    ListingTypeAll,
    ListingTypeAuction,
    ListingTypeFixedPrice,
    ListingTypeBestOffer
];
export var listingTypeMap = new Map<string, ListingType>();
for (var i = 0; i < ListingTypeOptions.length; i++) {
    listingTypeMap.set(ListingTypeOptions[i].id, ListingTypeOptions[i]);
}

export interface MostWatchedRequest {
    // basic
    keywords: string,
    categoryID: string,
    sortBy: string,
    sortOrder: string,
    site: string,
    status: string,
    // advanced
    listingType: string,
    freeShippingOnly: boolean,
    descriptionSearch: boolean,
    seller: string,
    minPrice: number | null,
    maxPrice: number | null,
    minBids: number | null,
    maxBids: number | null,
    // pagination
    offset: number
};

export function encodeMostWatchedRequest(mostWatchedRequest: MostWatchedRequest): string {
    const urlParams = new URLSearchParams();

    urlParams.append('keywords', mostWatchedRequest.keywords);
    urlParams.append('categoryID', mostWatchedRequest.categoryID);
    urlParams.append('listingType', mostWatchedRequest.listingType);
    urlParams.append('freeShippingOnly', mostWatchedRequest.freeShippingOnly.toString());
    urlParams.append('descriptionSearch', mostWatchedRequest.descriptionSearch.toString());
    urlParams.append('seller', mostWatchedRequest.seller);
    if (mostWatchedRequest.minPrice !== undefined && mostWatchedRequest.minPrice !== null) {
        urlParams.append('minPrice', mostWatchedRequest.minPrice.toString());
    }
    if (mostWatchedRequest.maxPrice !== undefined && mostWatchedRequest.maxPrice !== null) {
        urlParams.append('maxPrice', mostWatchedRequest.maxPrice.toString());
    }
    if (mostWatchedRequest.minBids !== undefined && mostWatchedRequest.minBids !== null) {
        urlParams.append('minBids', mostWatchedRequest.minBids.toString());
    }
    if (mostWatchedRequest.maxBids !== undefined && mostWatchedRequest.maxBids !== null) {
        urlParams.append('maxBids', mostWatchedRequest.maxBids.toString());
    }
    urlParams.append('sortBy', mostWatchedRequest.sortBy);
    urlParams.append('sortOrder', mostWatchedRequest.sortOrder);
    urlParams.append('status', mostWatchedRequest.status);
    urlParams.append('site', mostWatchedRequest.site);
    urlParams.append('offset', mostWatchedRequest.offset.toString());

    return urlParams.toString();
}

export interface MostWatchedResponse {
    startOffset: number,
    endOffset: number,
    previousOffset: number | null,
    nextOffset: number | null,
    total: number,
    limit: number,
    items: MostWatchedResponseItem[],
    requestID: string
}

export interface MostWatchedResponseItem {
    image: string,
    watchCount: number | null,
    title: string,
    id: string,
    listingType: string,
    startTime: Date | null,
    endTime: Date | null,
    status: string,
    bestOfferEnabled: boolean,
    price: number[],
    lastSoldFor: number[],
    recentlySoldFor: number| null,
    lastSoldDate: Date | null,
    currency: string,
    country: string,
    seller: string,
    sellerFeedbackScore: number,
    sellerFeedbackPercentage: string,
    bids: number | null,
    freeShipping: boolean,
    quantitySold: number | null,
    quantityAvailable: number | string | null,
    uniqueBidders: number | null,
    primaryCategory: string,
    primaryCategoryTree: string,
    secondaryCategory: string | null,
    secondaryCategoryTree: string | null,
    location: string | null,
    subtitle: string,
    originalPrice: number[] | null,
    discountType: string | null,
    marketplace: string | null
}

export interface Category {
    id: string,
    name: string
}

export interface SubcategoryTree {
    parent: Category,
    category: Category,
    children: Category[]
}

export interface LookupItemRequest {
    itemID: string,
    site: string
}

export function encodeLookupItemRequest(lookupItemRequest: LookupItemRequest): string {
    const urlParams = new URLSearchParams();

    urlParams.append('itemID', lookupItemRequest.itemID);
    urlParams.append('site', lookupItemRequest.site);

    return urlParams.toString();
}

export interface LookupItemResponse {
    itemID: string,
    site: string,
    image: string,
    title: string,
    listingType: string,
    endTime: Date | null,
    completed: boolean | null,
    seller: string,
    viewCount: number | null,
    watchCount: number[] | null,
    startTime: Date | null,
    price: number[],
    bids: number | null,
    currency: string,
    bestOfferEnabled: boolean | null,
    lastSoldFor: number | null,
    country: string,
    location: string,
    condition: string | null,
    categoryID: string,
    categoryPath: string,
    quantitySold: number[] | null,
    quantityAvailable: number[] | null,
    requestId: string
}