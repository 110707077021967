import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a5904bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-categories" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "py-2 py-lg-0",
        key: idx
      }, [
        _createElementVNode("a", {
          class: "category-link",
          href: '/mw2?categoryID=' + category.id
        }, _toDisplayString(category.name), 9, _hoisted_2)
      ]))
    }), 128))
  ]))
}