<template>
  <div class="row px-2">
    <div class="d-none d-lg-block col-auto pe-0">
      <DisplayBox title="Search">
        <SearchPresets></SearchPresets>
      </DisplayBox>
      <DisplayBox title="Categories">
        <TopCategories :categories="categories"></TopCategories>
      </DisplayBox>
    </div>
    <div class="offcanvas offcanvas-start d-block d-lg-none" id="offcanvas">
      <div class="offcanvas-header">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <DisplayBox title="Search">
          <SearchPresets></SearchPresets>
        </DisplayBox>
        <DisplayBox title="Categories">
          <TopCategories :categories="categories"></TopCategories>
        </DisplayBox>
      </div>
    </div>
    <div class="col px-0">
      <div class="container">
        <div class="row px-3 py-3 py-lg-3">
          <div class="col-12 notif">
            <div class="row">
              <div class="col">
                <div>
                  <strong><a :href="createFullRedirectURL('https://www.ebay.com/e/daily-deals/24-rw35-labor-day-main?mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5339077087&customid=&toolid=10001&mkevt=1', '', '')" target="_blank">Save 20% with eBay's Labor Day Coupon Code:  LABORDAYOFF</a> Valid on Tech, Fashion, Jewelry and more!</strong>
                </div>
                <div v-if="showNotification">
                  <hr class="mx-0 my-2">
                  <strong>WatchCount Updates:</strong> You can now search for both Most Watched Live and Completed items using the same form below.  Bugs, feedback or requests? Email <a href="mailto:feedback@watchcount.com">feedback@watchcount.com</a>.
                </div>
              </div>
              <div v-if="showNotification" class="col-auto">
                <button type="button" class="btn-close me-2 m-auto" @click="closeNotification"></button>
              </div>
            </div>
          </div>
        </div>
        <DisplayBox title="eBay's Most Popular Live and Completed Items" :menu="true">
          <FormMostWatched
            :categories="categories"
            :subcategoryTree="subcategoryTree"
            :mostWatchedRequest="mostWatchedRequest"
            :loading="mostWatchedLoading"
            @updateMostWatchedRequest="mostWatchedRequest = $event"
            @submit.prevent="submitSearch"
            @reset="subcategoryTree = undefined"
          ></FormMostWatched>
          <CountrySelector :country="localCountry" @updateCountry="localCountry = $event"></CountrySelector>
          <div class="row">
            <div class="col-0 col-lg-4"></div>
            <div class="col-12 col-lg-4 text-center feedback-text">
              Have feedback? Please email us at <a href="mailto:feedback@watchcount.com">feedback@watchcount.com</a>
            </div>
            <div class="col-0 col-lg-4"></div>
          </div>
        </DisplayBox>
        <DisplayBox v-if="mostWatchedLoading || mostWatchedResponse" :title="currMostWatchedRequest.status === statusLive.id ? 'Most Watched eBay Items/Auctions [Search Results]' : 'eBay Completed Items [Search Results]'">
          <div v-if="mostWatchedLoading" class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <MostWatchedResults
            v-if="mostWatchedResponse !== null"
            :request="currMostWatchedRequest"
            :results="mostWatchedResponse"
          ></MostWatchedResults>
          <div v-if="mostWatchedResponse !== null" class="row my-3">
            <div class="col-12 text-center col-lg-6">
              <span class="pagination">Showing {{ mostWatchedResponse.items.length }} of {{ mostWatchedResponse.total.toLocaleString() }} items</span>
            </div>
            <div class="col-12 text-center col-lg-6">
              <div class="row">
                <div v-if="mostWatchedResponse.previousOffset !== null" class="col">
                  <a href="#" class="link" @click.prevent="search(mostWatchedResponse.previousOffset)">Previous</a>
                </div>
                <div v-if="mostWatchedResponse.nextOffset !== null" class="col">
                  <a href="#" class="link" @click.prevent="search(mostWatchedResponse.nextOffset)">Next</a>
                </div>
              </div>
            </div>
          </div>
        </DisplayBox>
        <DisplayBox v-if="errorMsg !== ''">
          <div class="row my-3 text-center">
            <div class="col-12">
              <span class="warning">{{ errorMsg }}</span>
            </div>
          </div>
        </DisplayBox>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { 
  PropType,
  defineComponent
} from 'vue';

import {
  getEndpoint
} from '@/utils/auth';
import {deepCopy} from '@/utils/copy';
import {
    encodeMostWatchedRequest,
    Category,
    EbaySite,
    ListingTypeAll,
    ListingTypeAuction,
    ListingTypeBestOffer,
    ListingTypeFixedPrice,
    MostWatchedRequest,
    MostWatchedResponse,
    SortByWatchers,
    SortByBestMatch,
    SortByBids,
    SortByEndDate,
    SortByListDate,
    SortByPrice,
    SortOrderAscending,
    SortOrderDescending,
    StatusLive,
    SubcategoryTree,
    ebaySiteUS,
    ebaySiteUK,
    ebaySiteCA,
    ebaySiteAU,
    ebaySiteIE,
    ebaySiteDE,
    ebaySiteFR,
    ebaySiteES,
    ebaySiteIT,
    ebaySiteNL,
    ebaySiteBENL,
    ebaySiteBEFR,
    ebaySiteAT,
    ebaySiteCH,
    ebaySiteMY,
    ebaySiteIN,
    ebaySiteSG,
    ebaySitePH
} from '@/types';
import {
  createFullRedirectURL
} from '@/utils/redirector';

import TopCategories from '@/components/TopCategories.vue';
import DisplayBox from '@/components/DisplayBox.vue';
import FormMostWatched from '@/components/FormMostWatched.vue';
import MostWatchedResults from '@/components/MostWatchedResults.vue';
import CountrySelector from '@/components/CountrySelector.vue';
import SearchPresets from '@/components/SearchPresets.vue';

export default defineComponent({
  name: 'MostWatchedView',
  components: {
    TopCategories,
    DisplayBox,
    FormMostWatched,
    MostWatchedResults,
    CountrySelector,
    SearchPresets
  },
  props: {
    country: {
      type: Object as PropType<EbaySite>,
      required: true
    },
    categories: {
      type: Object as PropType<Array<Category>>,
    }
  },
  created() {
    if (('keywords' in this.$route.query || 'bkw' in this.$route.query) || (('categoryID' in this.$route.query || 'bcat' in this.$route.query) && (this.$route.query.categoryID !== '0' || this.$route.query.bcat !== '0') && (this.$route.query.categoryID !== '' || this.$route.query.bcat !== ''))) {
      this.submitSearch();
    }

    if (('categoryID' in this.$route.query || 'bcat' in this.$route.query) && (this.$route.query.categoryID !== '0' || this.$route.query.bcat !== '0') && (this.$route.query.categoryID !== '' || this.$route.query.bcat !== '')) {
      this.getSubcategoryTree(this.$route.query.categoryID as string);
    }

    // Show the alert if they didn't get out of it already.
    if (localStorage.getItem('seenNotification-8-3-2024') === null) {
      this.showNotification = true;
    }
  },
  data() {
    return {
      localCountry: deepCopy(this.country) as EbaySite,
      mostWatchedRequest: this.initMostWatchedRequest(),
      currMostWatchedRequest: this.initMostWatchedRequest(),
      mostWatchedLoading: false as boolean,
      mostWatchedResponse: null as MostWatchedResponse | null,
      subcategoryTree: undefined as SubcategoryTree | undefined,
      statusLive: StatusLive,
      errorMsg: '',
      showNotification: false,
      createFullRedirectURL: createFullRedirectURL
    }
  },
  methods: {
    initMostWatchedRequest() {
      let legacyWatchCountListingType = '';
      if (this.$route.query.csbin === 'auc') {
        legacyWatchCountListingType = ListingTypeAuction.id;
      } else if (this.$route.query.csbin === 'all') {
        legacyWatchCountListingType = ListingTypeAll.id;
      } else if (this.$route.query.csbin === 'boa') {
        legacyWatchCountListingType = ListingTypeFixedPrice.id;
      }

      let legacyWatchCountSortBy = '';
      let legacyWatchCountSortOrder = '';
      if (this.$route.query.cssrt === 'mw') {
        legacyWatchCountSortBy = SortByWatchers.id;
        legacyWatchCountSortOrder = SortOrderDescending.id;
      } else if (this.$route.query.cssrt === 'ts') {
        legacyWatchCountSortBy = SortByEndDate.id;
        legacyWatchCountSortOrder = SortOrderAscending.id;
      } else if (this.$route.query.cssrt === 'ph') {
        legacyWatchCountSortBy = SortByPrice.id;
        legacyWatchCountSortOrder = SortOrderDescending.id;
      } else if (this.$route.query.cssrt === 'pl') {
        legacyWatchCountSortBy = SortByPrice.id;
        legacyWatchCountSortOrder = SortOrderAscending.id;
      } else if (this.$route.query.cssrt === 'bm') {
        legacyWatchCountSortBy = SortByBestMatch.id;
        legacyWatchCountSortOrder = SortOrderDescending.id;
      }

      let legacyWatchCountCountry = '';
      if (this.$route.query.cc === 'AT') {
        legacyWatchCountCountry = ebaySiteAT.id;
      } else if (this.$route.query.cc === 'AU') {
        legacyWatchCountCountry = ebaySiteAU.id;
      } else if (this.$route.query.cc === 'BENL') {
        legacyWatchCountCountry = ebaySiteBENL.id;
      } else if (this.$route.query.cc === 'CA') {
        legacyWatchCountCountry = ebaySiteCA.id;
      } else if (this.$route.query.cc === 'CH') {
        legacyWatchCountCountry = ebaySiteCH.id;
      } else if (this.$route.query.cc === 'DE') {
        legacyWatchCountCountry = ebaySiteDE.id;
      } else if (this.$route.query.cc === 'ES') {
        legacyWatchCountCountry = ebaySiteES.id;
      } else if (this.$route.query.cc === 'FR') {
        legacyWatchCountCountry = ebaySiteFR.id;
      } else if (this.$route.query.cc === 'IE') {
        legacyWatchCountCountry = ebaySiteIE.id;
      } else if (this.$route.query.cc === 'IN') {
        legacyWatchCountCountry = ebaySiteIN.id;
      } else if (this.$route.query.cc === 'IT') {
        legacyWatchCountCountry = ebaySiteIT.id;
      } else if (this.$route.query.cc === 'MY') {
        legacyWatchCountCountry = ebaySiteMY.id;
      } else if (this.$route.query.cc === 'NL') {
        legacyWatchCountCountry = ebaySiteNL.id;
      } else if (this.$route.query.cc === 'SG') {
        legacyWatchCountCountry = ebaySiteSG.id;
      } else if (this.$route.query.cc === 'UK') {
        legacyWatchCountCountry = ebaySiteUK.id;
      } else if (this.$route.query.cc === 'US') {
        legacyWatchCountCountry = ebaySiteUS.id;
      }

      let legacyWatchCountCategory = '';
      if (this.$route.query.bcat !== '') {
        legacyWatchCountCategory = this.$route.query.bcat as string;
        if (legacyWatchCountCategory === '0') {
          legacyWatchCountCategory = '';
        }
      }

      let legacyWatchCountMaxBids = '';
      if (this.$route.query.hasOwnProperty('zero')) {
        legacyWatchCountMaxBids = '0';
      }

      return {
        keywords: this.$route.query.keywords || this.$route.query.bkw || '',
        categoryID: this.$route.query.categoryID || legacyWatchCountCategory || '',
        listingType: this.$route.query.listingType || legacyWatchCountListingType || ListingTypeAll.id,
        freeShippingOnly: this.$route.query.freeShippingOnly || (this.$route.query.bfso === '1') || false,
        descriptionSearch: this.$route.query.descriptionSearch || (this.$route.query.bds === '1') || false,
        seller: this.$route.query.seller || this.$route.query.bslr || '',
        minPrice: this.$route.query.minPrice || this.$route.query.bnp || null,
        maxPrice: this.$route.query.maxPrice || this.$route.query.bxp || null,
        minBids: this.$route.query.minBids || null,
        maxBids: this.$route.query.maxBids || legacyWatchCountMaxBids || null,
        sortBy: this.$route.query.sortBy || legacyWatchCountSortBy || SortByWatchers.id,
        sortOrder: this.$route.query.sortOrder || legacyWatchCountSortOrder || SortOrderDescending.id,
        status: this.$route.query.status || StatusLive.id,
        site: this.$route.query.site || legacyWatchCountCountry || this.country.id,
        offset: this.$route.query.offset || 0
      } as MostWatchedRequest;
    },
    submitSearch() {
      this.currMostWatchedRequest = deepCopy(this.mostWatchedRequest);
      this.search(this.currMostWatchedRequest.offset);
    },
    search(offset: number) {
      this.mostWatchedResponse = null;
      this.mostWatchedLoading = true;

      this.currMostWatchedRequest.offset = offset;

      this.$router.push({
        path: this.$route.path,
        query: this.currMostWatchedRequest
      });

      this.subcategoryTree = undefined;

      this.errorMsg = '';

      fetch(
        getEndpoint() + '/mw2?' + encodeMostWatchedRequest(this.currMostWatchedRequest),
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(
        async response => {
          if (response.ok) {
            if (this.currMostWatchedRequest.categoryID !== '') {
              this.getSubcategoryTree(this.currMostWatchedRequest.categoryID);
            }
            this.mostWatchedResponse = await response.json();
          } else if (await response.text() === '100015') {
            this.errorMsg = 'This category is not supported for Completed Search.'; 
          }  else {
            this.errorMsg = 'An error occurred while searching. Please double-check the search inputs and make sure there is at least 1 keyword or category selected.'
          }
        }
      ).catch(
        error => {
          this.errorMsg = 'An error occurred while searching. Please double-check the search inputs and make sure there is at least 1 keyword or category selected.'
        }
      ).finally(
        () => {
          this.mostWatchedLoading = false;
        }
      );
    },
    getSubcategoryTree(categoryID: string) {      
      fetch(
        getEndpoint() + '/get_category_subtree/' + categoryID + '?site=' + this.country.id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(
        async response => {
          if (response.ok) {
            this.subcategoryTree = await response.json();
          }
        }
      )
    },
    closeNotification() {
      this.showNotification = false;
      localStorage.setItem('seenNotification-8-3-2024', 'true');
    }
  },
  watch: {
    localCountry: {
      handler(val: EbaySite) {
        this.$emit('updateCountry', val);
      },
      deep: true
    }
  }
});
</script>

<style scoped>
  .pagination {
    color: #7a7a7a;
    font-size: 10pt;
    font-weight: normal;
    font-family: Tahoma,Verdana,Arial,Helvetica;
  }

  .link {
    color: #7812cc;
    text-decoration: none;
    font-size: 12px;
    font-weight: 700;
    font-family: Arial, Tahoma, Verdana;
  }

  .feedback-text {
        font-family: Tahoma,Verdana,Helvetica;
        font-size: 8pt;
    }

  .notif {
    border: 1px solid black;
  }

  .offcanvas {
    overflow: scroll !important;
  }

  .offcanvas-body {
    padding: 5px 0px;
  }
  
  .coupon {
  }
</style>