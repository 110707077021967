<template>
  <div class="container">
    <Box>
      <Header :country="country"></Header>
      <div v-if="categoriesLoading" class="row">
        <div class="col-12 text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <router-view v-else :country="country" :categories="level1Categories" @updateCountry="country = $event" />
    </Box>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Box from '@/components/Box.vue';
import Header from '@/components/Header.vue';
import DisplayBox from '@/components/DisplayBox.vue';

import {
  getEndpoint
} from '@/utils/auth';
import {
  getCountry 
} from "@/utils/country";
import {
  Category
} from '@/types';

export default defineComponent({
  name: 'App',
  components: {
    Box,
    Header,
    DisplayBox
  },
  created() {
    this.getLevel1Categories();
  },
  data() {
    return {
      country: getCountry(),
      level1Categories: [] as Category[],
      categoriesLoading: false as boolean
    }
  },
  methods: {
    getLevel1Categories() {
      this.categoriesLoading = true;

      fetch(
        getEndpoint() + '/get_level_1_categories?site=' + this.country.id,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(
        async response => {
          if (response.ok) {
            this.level1Categories = await response.json();
          } else if (response.status === 401) {
            alert('Your session has expired.');
          } else {
            alert('Error loading categories. Please try again later.');
          }
        }
      ).catch(
        error => {
          alert('Error loading categories. Please try again later.');
        }
      ).finally(
        () => {
          this.categoriesLoading = false;
        }
      )
    }
  }
});
</script>