import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-232138d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-center my-4 my-lg-2" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (ctry, idx) => {
      return (_openBlock(), _createElementBlock("a", {
        key: idx,
        class: _normalizeClass(["country-code", ctry.code === _ctx.localCountry.code ? 'selected' : '']),
        href: "#",
        onClick: _withModifiers(($event: any) => (_ctx.localCountry = ctry), ["prevent"])
      }, _toDisplayString(ctry.code), 11, _hoisted_2))
    }), 128))
  ]))
}