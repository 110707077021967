import { createRouter, createWebHistory } from 'vue-router'

import MostWatchedView from '../views/MostWatchedView.vue';
import ItemLookupView from '../views/ItemLookupView.vue';

import {
  getCountry 
} from "@/utils/country";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/mw2',
    },
    {
      path: '/mw2',
      name: 'most-watched',
      component: MostWatchedView
    },
    {
      path: '/itm',
      name: 'lookup-item',
      component: ItemLookupView
    },
    {
      path: '/bids.php',
      redirect: to => {
        return {
          name: 'most-watched',
          query: {...to.query, sortBy: 'bids', sortOrder: 'desc', listingType: 'auction'}
        }
      }
    },
    {
      path: '/completed.php',
      redirect: to => {
        return {
          name: 'most-watched',
          query: {...to.query, site: 'EBAY_US', status: 'completed', sortBy: 'price', sortOrder: 'desc'}
        }
      }
    },
    {
      path: '/ebay.php',
      redirect: to => {
        return {
          name: 'most-watched',
          query: {...to.query, sortBy: 'bestmatch'}
        }
      }
    },
    {
      path: '/:pathMath(.*?)*',
      redirect: '/'
    }
  ]
});

export default router
