<template>
    <div class="container">
        <DisplayBox title="eBay Item Lookup">
            <span v-if="lookupItemLoading" class="text-center">Loading...</span>
            <LookupItemResult v-if="lookupItemResponse !== null" :request="lookupItemRequest" :result="lookupItemResponse"></LookupItemResult>
            <div v-if="errorMsg !== ''">
                <div class="row my-3 text-center">
                    <div class="col-12">
                    <span class="warning">{{ errorMsg }}</span>
                    </div>
                </div>
            </div>
        </DisplayBox>
    </div>
</template>

<script lang="ts">
import {
    PropType,
    defineComponent
} from 'vue';

import {
    getEndpoint
} from '@/utils/auth';
import {deepCopy} from '@/utils/copy';
import {
    encodeLookupItemRequest,
    EbaySite,
    Category,
    LookupItemRequest,
    LookupItemResponse,
} from '@/types';

import DisplayBox from '@/components/DisplayBox.vue';
import LookupItemResult from '@/components/LookupItemResult.vue';

export default defineComponent({
    name: 'ItemLookupView',
    components: {
        DisplayBox,
        LookupItemResult
    },
    props: {
        country: {
            type: Object as PropType<EbaySite>,
            required: true
        },
        categories: {
            type: Object as PropType<Array<Category>>,
        }
    },
    created() {
        this.lookupItem();
    },
    data() {
        return {
            lookupItemRequest: {
                itemID: this.$route.query.itemID,
                site: this.country.id
            } as LookupItemRequest,
            lookupItemLoading: false,
            lookupItemResponse: null as LookupItemResponse | null,
            errorMsg: ''
        }
    },
    methods: {
        lookupItem() {
            this.lookupItemResponse = null;
            this.lookupItemLoading = true;
            this.errorMsg = '';

            fetch(
                getEndpoint() + '/itm?' + encodeLookupItemRequest(this.lookupItemRequest),
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            ).then(
                async response => {
                    if (response.ok) {
                        this.lookupItemResponse = await response.json();
                    } else {
                        this.errorMsg = 'An error occurred while searching. Please try again later.';
                    }
                }
            ).catch(
                error => {
                    this.errorMsg = 'An error occurred while searching. Please try again later.';
                }
            ).finally(
                () => {
                    this.lookupItemLoading = false;
                }
            )
        }
    }
})
</script>