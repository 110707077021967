<template>
    <div class="text-center my-4 my-lg-2">
        <a v-for="(ctry, idx) in countries" :key="idx" class="country-code" :class="ctry.code === localCountry.code ? 'selected' : ''" href="#" @click.prevent="localCountry = ctry">
            {{ ctry.code }}
        </a>
    </div>
</template>

<script lang="ts">
import {
    PropType,
    defineComponent
} from "vue";

import {deepCopy} from '@/utils/copy';
import {
    setCountryCode
} from '@/utils/country';
import {
    EbaySite,
    ebaySites
} from '@/types';

export default defineComponent({
    name: 'CountrySelector',
    props: {
        country: {
            type: Object as PropType<EbaySite>,
            required: true
        }
    },
    data() {
        return {
            localCountry: deepCopy(this.country) as EbaySite,
            countries: ebaySites
        }
    },
    watch: {
        localCountry: {
            handler(val: EbaySite) {
                setCountryCode(val.id);
                
                const currentURL = window.location.href;

                let newURL = new URL(currentURL);
                newURL.search = '';
                newURL.searchParams.set('site', val.id);

                window.location.href = newURL.toString();
            },
            deep: true
        }
    }
});
</script>

<style scoped>
    .country-code {
        font-family: Verdana,Geneva,Tahoma;
        font-size: 7pt;
        font-weight: normal;
        color: #ad00cc;
        text-decoration: none;
        margin: 0px 5px;
        display: inline-block;
    }

    .selected {
        font-weight: bold;
        color: #1a1a1a;
        background: #daffb3;
        border: 2px groove #e0e0e0;
        padding: 0px 3px 2px 3px;
    }
</style>