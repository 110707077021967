<template>
  <div class="box">
      <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Box',
});
</script>

<style scoped>
  .box {
    border: 2px ridge #ededed;
    background-color: #ffffff;
  }
</style>