<template>
    <div class="results">
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col-12 text-center col-lg-4 text-lg-start my-2 my-lg-0">
                        eBay Site: <a class="site-link" :href="siteRedirectURL" target="_blank">{{ country.site }}</a>
                    </div>
                    <div class="col-12 text-center col-lg-4 text-lg-start my-2 my-lg-0">
                        Page <span v-if="results.limit === 0">1</span><span v-else>{{ request.offset / results.limit + 1 }}</span>
                    </div>
                    <div class="col-12 text-center col-lg-4 text-lg-start my-2 my-lg-0">
                        [{{ listingTypeMap.get(request.listingType).name }}]
                    </div>
                </div>
                <div v-if="request.keywords" class="row">
                    <div class="col-12 text-center text-lg-start my-2 my-lg-0">
                        Search Keywords: {{ request.keywords }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center col-lg-4 text-lg-start my-2 my-lg-0">
                        Sort: {{ sortByMap.get(request.sortBy).name }}
                    </div>
                    <div class="col-12 text-center col-lg-8 text-lg-start disclosure my-2 my-lg-0">
                        [Disclosure: This site contains affiliate links for which we may be compensated.]
                    </div>
                </div>
            </div>
            <div class="col-12 text-center col-lg-auto my-2 my-lg-0">
                <img :src="require('@/assets/right-now-on-ebay.gif')">
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center my-2 disclosure">
                
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="container">
                    <div v-for="(item, idx) in results.items" :key="idx" class="row">
                        <div class="col-12">
                            <MostWatchedResultsItem :request="request" :results="results" :item="item"></MostWatchedResultsItem>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { 
    PropType,
    defineComponent
} from "vue";

import {
    ebaySiteMap,
    listingTypeMap,
    sortByMap,
    encodeMostWatchedRequest,
    MostWatchedRequest,
    MostWatchedResponse
} from '@/types';
import {
    getCountry
} from '@/utils/country';
import {
    createRedirectURL
} from '@/utils/redirector';

import MostWatchedResultsItem from '@/components/MostWatchedResultsItem.vue';

export default defineComponent({
    name: 'MostWatchedResults',
    components: {
        MostWatchedResultsItem
    },
    props: {
        request: {
            type: Object as PropType<MostWatchedRequest>,
            required: true
        },
        results: {
            type: Object as PropType<MostWatchedResponse>,
            required: true
        }
    },
    data() {
        return {
            'country': getCountry(),
            'listingTypeMap': listingTypeMap,
            'sortByMap': sortByMap,
            'search': encodeMostWatchedRequest(this.request),
            'siteRedirectURL': createRedirectURL(getCountry().site, '', '', this.request.status, encodeMostWatchedRequest(this.request), this.results.requestID)
        }
    }
});
</script>

<style scoped>
    .results {
        background-color: #f6f6ff;
        font-family: Calibri,Arial,Verdana,Tahoma;
        font-size: 11pt;
    }
    .site-link {
        color: #7812cc;
        text-decoration: none;
        font-family: Tahoma,Arial,Verdana,Helvetica;
        font-weight: bold;
        font-size: 10pt;
    }
    .site-link:hover {
        color: 3002699;
        text-decoration: underline;
    }
    .disclosure {
    }
</style>